import { useState, useEffect } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { CodeBlock, dracula } from 'react-code-blocks';
import { formatCode } from '../Helpers/Utils';

const CodeBlockView = ({ title, showLineNumbers = false, language = 'json', response, codeBlock = true }) => {
  const [postProcessing, setPostProcessing] = useState('');

  useEffect(() => {
    if (response === null) return;
    async function fetchData() {
      setPostProcessing(await formatCode(response, 'babel', language !== 'json'));
    }
    fetchData();
  }, [response]);

  return response === null ? null : (
    <VStack alignItems={'left'} width={'100%'}>
      {title === null ? null : (
        <Text fontSize={'md'} fontWeight={'bold'}>
          {title}
        </Text>
      )}
      <CodeBlock
        text={postProcessing}
        language={language}
        showLineNumbers={showLineNumbers}
        theme={dracula}
        codeBlock={codeBlock}
      />
    </VStack>
  );
};

export default CodeBlockView;
