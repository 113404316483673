import { VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import FeedRowView from '../Custom/FeedRowView';
import { combine } from '../Helpers/Utils';
import { log } from '../Helpers/Logger';
import ExpandableView from '../Custom/ExpandableView';

const Endpoint = ({ endpoint, apiSpecifications, oisTitle }) => {
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setFeeds(await combine(endpoint, error));
    }
    fetchData();
  }, [endpoint]);

  const error = (error) => {
    log('ERROR', [error]);
  };

  return (
    <VStack alignItems={'left'}>
      {feeds.map((feed, index) => (
        <VStack key={index} alignItems={'left'} width={'100%'}>
          <ExpandableView
            view={
              <FeedRowView endpoint={endpoint} feed={feed} apiSpecifications={apiSpecifications} oisTitle={oisTitle} />
            }
            header={feed.feed}
          />
        </VStack>
      ))}
    </VStack>
  );
};

export default Endpoint;
